@mixin text-fields($pseudo-class: "") {
  textarea#{$pseudo-class},
  input[type="text"]#{$pseudo-class},
  input[type="password"]#{$pseudo-class},
  input[type="datetime"]#{$pseudo-class},
  input[type="datetime-local"]#{$pseudo-class},
  input[type="date"]#{$pseudo-class},
  input[type="month"]#{$pseudo-class},
  input[type="time"]#{$pseudo-class},
  input[type="week"]#{$pseudo-class},
  input[type="number"]#{$pseudo-class},
  input[type="email"]#{$pseudo-class},
  input[type="url"]#{$pseudo-class},
  input[type="search"]#{$pseudo-class},
  input[type="tel"]#{$pseudo-class} {
    @content;
  }
}

@mixin option-fields($pseudo-class: "") {
  input[type="radio"]#{$pseudo-class},
  input[type="checkbox"]#{$pseudo-class} {
    @content;
  }
}

@mixin text-fields-placeholder($placeholder-class: null) {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";

  @each $placeholder in $placeholders {
    :#{$placeholder}-placeholder {
      @content;
    }
  }

  @if $placeholder-class {
    .#{$placeholder-class} {
      @content;
    }
  }

  ::placeholder {
    @content;
  }
}
