body {
  overflow-x: hidden;
}

// Sticky footer

html,
body {
  height: 100%;
}

#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
}
