.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-primary {
  font-weight: 600;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $white;
  }

  &.disabled {
    background-color: #e4e8eb;
    color: #adb9c1;
    border-color: #e4e8eb;
    opacity: 1;
    svg {
      fill: #adb9c1
    }
  }

  &:disabled {
    color: #fff;
    svg {
      fill: #fff;
    }
  }
}

.btn-secondary {
  color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $white;
  }

  &:hover {
    background: #f7a65e;
    border-color: #f7a65e;
  }

  &:disabled,
  &.disabled {
    color: $white;
  }
}

.btn-outline-gray {
  color: $body-color;

  &:hover,
  &:focus,
  &:active {
    color: $white;
  }
}

.btn-header {
  padding: 0.75rem 2rem;
  border-width: 1.5px;
  font-size: 14px;
  font-weight: 600;

  @include media-breakpoint-down(lg) {
    padding: 0.5rem 1rem;
    border-radius: 1rem;
  }
}
