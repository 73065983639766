.pot-1 {
  position: relative;
  top: 1px;
}

.pot-2 {
  position: relative;
  top: 2px;
}

.pot-3 {
  position: relative;
  top: 3px;
}

.pot-4 {
  position: relative;
  top: 4px;
}

.pot-5 {
  position: relative;
  top: 5px;
}

.pot-n1 {
  position: relative;
  top: -1px;
}

.pot-n2 {
  position: relative;
  top: -2px;
}

.pot-n3 {
  position: relative;
  top: -3px;
}

.pot-n4 {
  position: relative;
  top: -4px;
}

.pot-n5 {
  position: relative;
  top: -5px;
}
