.cc-theme-gleevery #cc-main {

    --cc-font-family: $font-family-sans-serif;

    --cc-btn-primary-bg: #{$yellow};
    --cc-btn-primary-border-color: #{$yellow};
    --cc-btn-primary-hover-bg: #444444;
    --cc-btn-primary-hover-border-color: #444444;

    --cc-toggle-on-bg: var(--cc-btn-primary-bg);

    --cc-btn-border-radius: 10px;
   
}

.cc-theme-gleevery #cc-main .cm__texts{
    padding: 38px 10px 10px 10px;
}