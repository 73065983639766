/* Get space lenght based on space size from spacers list */
@function space($size, $spacers: $spacers) {
  @return map-get($spacers, $size);
}

/* Print spacing margins or paddings */
@mixin space($size, $direction: all, $prop: margin ) {

  $length: space($size);

  @if $direction == all {
    #{$prop}: $length;
  }

  @if $direction == y {
    #{$prop}-top: $length;
    #{$prop}-bottom: $length;
  }

  @if $direction == x {
    #{$prop}-left: $length;
    #{$prop}-right: $length;
  }

  @if $direction == t {
    #{$prop}-top: $length;
  }

  @if $direction == b {
    #{$prop}-bottom: $length;
  }

  @if $direction == l {
    #{$prop}-left: $length;
  }

  @if $direction == r {
    #{$prop}-right: $length;
  }
}

/* Print spacing margins */
@mixin space-margin($size, $direction: all ) {
  @include space($size, $direction);
}

/* Print spacing margins */
@mixin space-padding($size, $direction: all ) {
  @include space($size, $direction, padding);
}
