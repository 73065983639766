@use "sass:math";

@mixin typography() {
  :root {
    font-size: 16px;
  }

  body {
    font-weight: $font-weight-medium;

    @include font-size($font-size-base);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    margin-top: $headings-margin-top;
    margin-bottom: $headings-margin-bottom;
    color: $headings-color;

    b,
    strong {
      font-weight: 700;
    }
  }

  p,
  .p {
    margin-top: space(5);
    margin-bottom: space(5);
    font-size: 0.9375rem;
    line-height: 1.5rem;
  }

  .p {
    display: block;
  }

  ul,
  ol {
    margin-top: space(4);
    margin-bottom: space(4);
    padding-left: space(5);
    font-size: 0.9375rem;
    line-height: 1.5rem;
  }

  ul {
    // inline SVG as background image
    list-style-image: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='UTF-8'?><svg width='24' height='14' viewBox='0 0 24 14' xmlns='http://www.w3.org/2000/svg'><line x1='6.5' y1='8.5' x2='17.5' y2='8.5' stroke='%23000' stroke-width='1.25'></line></svg>");

    ul {
      list-style-image: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='UTF-8'?><svg width='16' height='14' xmlns='http://www.w3.org/2000/svg'><circle fill='%23000' cx='1.25' cy='8.5' r='1.25'></circle></svg>");
    }
  }

  ul ul {
    list-style-type: square;
  }

  li {
    margin-bottom: space(2);
    font-size: 0.9375rem;
    line-height: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  hr {
    width: 100%;
    height: 1px;
    margin-top: space(4);
    margin-bottom: space(4);
    border: 0;
    background: $gray-600;
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  a {
    transition: $transition-base;
  }

  a[href^="tel:"] {
    color: $body-color;

    &:hover {
      text-decoration: none;

      svg,
      use {
        text-decoration: none;
      }
    }
  }

  .link-disable {
    &:hover {
      text-decoration: none;
    }
  }

  .link-alt {
    transition: $transition-base;
    color: $body-color;

    &:hover {
      text-decoration: none;
      color: $primary;
    }
  }

  .fw-medium {
    font-weight: $font-weight-medium;
  }

  .fw-semibold {
    font-weight: $font-weight-semibold;
  }

  .fw-extrabold {
    font-weight: $font-weight-extrabold;
  }

  .lh-0 {
    line-height: 0;
  }

  .lh-1 {
    line-height: 1;
  }

  .lh-xl {
    line-height: $line-height-xl;
  }

  .lh-l {
    line-height: $line-height-l;
  }

  .lh-n {
    line-height: $line-height-n;
  }

  .lh-s {
    line-height: $line-height-s;
  }

  .lh-xs {
    line-height: $line-height-xs;
  }

  .lh-xxs {
    line-height: $line-height-xxs;
  }

  .lh-xxs {
    line-height: 1.05rem;
  }

  .fs-xs {
    line-height: 1.4;
  }

  .text-cool-gray-800 {
    color: $cool-gray-800;
  }
}

@include typography;
