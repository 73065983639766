@use "sass:math";

form {
  @include space-margin(5, y);
}

@include text-fields() {
  font-family: $input-font-family;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  color: $input-color;
  border: $input-border-width solid $input-border-color;
  background-color: $input-bg;
  background-clip: padding-box;

  /* Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS. */
  @include border-radius($input-border-radius, 0);
  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  &:disabled,
  &[readonly] {
    /* iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655. */
    opacity: 1;
    background-color: $input-disabled-bg;
  }

  &::placeholder {
    color: $input-placeholder-color;
  }
}

@include text-fields(":focus") {
  color: $input-focus-color;
  border-color: $input-focus-border-color;
  outline: 0;
  background-color: $input-focus-bg;

  /* Avoid using mixin so we can pass custom focus shadow properly */
  @if $enable-shadows {
    box-shadow: $input-box-shadow, $input-focus-box-shadow;
  } @else {
    box-shadow: $input-focus-box-shadow;
  }
}

textarea {
  height: 128px;
}

$form-select-transition: $input-transition;

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: $form-select-bg;
  background-clip: padding-box;
  background-image: escape-svg($form-select-indicator);
  background-repeat: no-repeat;
  background-position: $form-select-bg-position;
  background-size: $form-select-bg-size;
  color: $form-select-color;
  border: $input-border-width solid $input-border-color;
  width: 100%;
  height: $input-height;
  font-family: $input-font-family;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  padding: $input-padding-y $input-padding-x;
}

select option {
  font-size: $input-font-size;
  // padding: $input-padding-y $input-padding-x;
  padding: 3rem $input-padding-x;
  z-index: 999;
}

select option li {
  padding: $input-padding-y $input-padding-x;
}

select option li:hover {
}

input[type="checkbox"],
input[type="radio"] {
  width: $form-check-input-width;
  height: $form-check-input-width;
  margin-top: 0;
  vertical-align: top;
  background-color: $form-check-input-bg;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: $form-check-input-border;
  appearance: none;
  color-adjust: exact; // Keep themed appearance for print
  @include transition($form-check-transition);

  &:active {
    filter: $form-check-input-active-filter;
  }

  &:focus {
    outline: 0;
  }

  &:checked {
    background-color: $form-check-input-checked-bg-color;
    border-color: $form-check-input-checked-border-color;
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: $form-check-input-disabled-opacity;
  }
}

input[type="checkbox"] {
  @include border-radius($form-check-input-border-radius);

  &:checked {
    background-image: escape-svg($form-check-input-checked-bg-image);
  }

  &:indeterminate {
    background-color: $form-check-input-indeterminate-bg-color;
    border-color: $form-check-input-indeterminate-border-color;

    background-image: escape-svg($form-check-input-indeterminate-bg-image);
  }
}

input[type="radio"] {
  border-radius: $form-check-radio-border-radius;
  &:checked {
    background-image: escape-svg($form-check-radio-checked-bg-image);
  }
}

.field {
  margin: space(4) 0;
}

// Select field
.field--select {
}

.field--select__input {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: space(2);
}

// Checkbox field
.field--checkbox {
  display: flex;
}

.field--checkbox__input {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: space(2);
  width: $form-check-input-width;
  height: $form-check-input-width;
}

.field--checkbox__label {
  flex-grow: 1;
}

// Radio field
.field--radio {
  // display: flex;
}

.field--radio__input {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: space(2);
  width: $form-check-input-width;
  height: $form-check-input-width;
  margin-top: 2px;
  font-size: $font-size-base;
}

.field--radio__label {
  flex-grow: 1;
}

.field--radio--border {
  margin-top: 0;
  margin-bottom: 0;
}

// Required
.field__reqin {
  font-style: normal;
  color: $danger;
  margin-left: math.div(space(1), 2);
}

// Disabled {
.field--disabled__label {
  opacity: 0.5;
}

// Errors
.field__errors {
  margin-top: space(2);
  font-size: $form-feedback-font-size;
  color: $form-feedback-invalid-color;

  @extend %rce;

  p {
    margin-top: space(1);
    margin-bottom: space(1);
    font-size: 0.8125rem;
    line-height: 1.4;
  }
}

.field--errors__input {
  @include text-fields() {
    border-color: $form-feedback-invalid-color;
    box-shadow: 0 0 0 0.25rem rgba($form-feedback-invalid-color, 0.25);
  }
}

// Description
.field__description {
  margin-top: space(2);
  font-size: map-get($font-sizes, "xs");
  color: $gray;
}

.field__description--white {
  color: $white;

  svg {
    fill: $white;
  }
}

// Sizes
.field-lg {
  @include text-fields() {
    font-size: $input-font-size-lg;
    height: $input-height-lg;
    line-height: $input-line-height-lg;
    padding: $input-padding-y-lg $input-padding-x-lg;
  }

  > .field__label {
    label {
      font-size: $input-font-size-lg;
    }
  }
}

.field-sm {
  @include text-fields() {
    font-size: $input-font-size-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    line-height: $input-line-height-sm;
    height: $input-height-sm;
    @include border-radius($input-border-radius-sm, 0);
  }

  input[type="checkbox"],
  input[type="radio"] {
    margin-top: 0.5rem;
  }

  > .field__label {
    label {
      font-size: $input-font-size-sm;
      line-height: $line-height-sm;
    }
  }
}

// Groups
.field--group__input {
  display: flex;

  @include text-fields() {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  > .btn:last-child {
    margin-left: -#{space(5)};
  }
}

// Loading

.field--loading__input,
.field--success__input {
  position: relative;
}

.field__loader {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  line-height: 1;

  svg {
    width: 24px;
    height: 24px;
    fill: #6c757d;
  }
}

.field__success {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  line-height: 1;

  svg {
    width: 24px;
    height: 24px;
    fill: $cyan;
  }
}

// Floating labels

.field-fl {
  position: relative;

  > .field__input {
    textarea {
      height: 128px;
    }
  }

  > .field__input.field--placeholder__input {
    @include text-fields() {
      padding: $input-padding-y $input-padding-x;
    }
  }

  > .field__label {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: $input-height; // allow textareas
    padding: $form-floating-padding-y $form-floating-padding-x $form-floating-padding-y #{$form-floating-padding-x - 0.25rem};
    pointer-events: none;
    border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform: $form-floating-label-transform;
    transform-origin: 0 0;
    @include transition($form-floating-transition);

    label {
      font-size: $input-focus-label-font-size;
      color: $input-focus-label-color;
      padding: 0 0.25rem;
      transition: font-size 0.1s ease-in-out;
      span {
        display: inline-block;
      }
      &:after {
        display: block;
        width: 100%;
        height: $input-border-width;
        background: white;
        content: "";
        position: relative;
        transform: translateY(calc(#{$input-height} / 4 * -1));
        box-shadow: $input-label-box-shadow;
        z-index: -1;
      }
    }
  }

  > .field__label.field--placeholder__label {
    color: $input-label-color;
    font-size: $input-font-size;
    transform: none;
    label:after {
      content: none;
    }
  }

  &.field--placeholder {
    > .field__label label {
      font-size: $input-font-size;
    }
  }

  &.field-lg {
    > .field__input {
      @include text-fields() {
        height: $input-height-lg;
        padding: $input-padding-y-lg $input-padding-x-lg;
      }
    }

    > .field__input.field--placeholder__input {
      @include text-fields() {
        padding: $input-padding-y-lg $input-padding-x-lg;
      }
    }

    > .field__label {
      transform: $form-floating-label-transform-lg;
      height: add($input-line-height * $input-font-size-lg, add($input-padding-y-lg * 2, $input-height-border, false));
      &.field--placeholder__label {
        transform: translateY(0rem) translateX(0rem);
      }
      label {
        &:after {
          box-shadow: $input-label-box-shadow-lg;
        }
      }
    }

    &.field--placeholder {
      > .field__label label {
        font-size: $input-font-size-lg;
      }
    }
  }

  &.field-sm {
    > .field__input {
      @include text-fields() {
        height: $input-height-sm;
        padding: $input-padding-y-sm $input-padding-x-sm;
      }
    }

    > .field__input.field--placeholder__input {
      @include text-fields() {
        padding: $input-padding-y-sm $input-padding-x-sm;
      }
    }

    > .field__label {
      transform: $form-floating-label-transform-sm;
      height: $input-height-sm;
      padding: $form-floating-padding-y-sm $form-floating-padding-x-sm;
      &.field--placeholder__label {
        transform: translateY(-0.05rem) translateX(0rem);
      }
      label {
        &:after {
          box-shadow: $input-label-box-shadow-sm;
        }
      }
    }

    > .field__errors p {
      font-size: $input-font-size-sm;
      line-height: $line-height-sm;
    }

    &.field--placeholder {
      > .field__label label {
        font-size: $input-font-size-sm;
      }
    }
  }
}

.radio-wrapper:hover {
  background-color: #F5F7F7;
}

.radio-wrapper-checked {
  padding: 22px;
}

.radio-wrapper-unchecked {
  padding: 24px;
}

.btn-sm {
  font-size: $input-btn-font-size-sm;
  line-height: $input-btn-line-height-sm;
  padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
}

.btn-lg {
  font-size: $input-btn-font-size-lg;
  line-height: $input-btn-line-height-lg;
  padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
}
